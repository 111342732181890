import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Scroller from "../components/scroller"
import PortfolioModal from "../components/portfolio/modal"
import PortfolioCarousel from "../components/portfolio/carousel"

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    Scroller.handleAnchorScroll = Scroller.handleAnchorScroll.bind(this)
    this.state = {
      modalShow: false,
      modalCurrent: 0
    }
    this.handlePortfolioClick = this.handlePortfolioClick.bind(this);
    this.setModal = this.setModal.bind(this);
  }

  handlePortfolioClick(index, e) {
    e.preventDefault();
    this.setModal(true, index);
  }

  setModal(isShown, current) {
    this.setState({
      modalShow: isShown,
      modalCurrent: current
    });
  }

  render() {
    return (
      <Layout>
        <SEO title="Home"/>
        <section className="page-section" id="about">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 text-center">
                <h2 className="mt-0">ABOUT ME</h2>
                <hr className="divider my-4"/>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8">
                <p className="mb-4 text-left text-muted">
                  My name is Wilson Underkuffler and I am a realtor with Brick Realty in Ogden, Utah.
                  As Ogden's Real Estate Guru, I utilize statistical reasoning and hyper-specific neighborhood knowledge
                  to guide you through your home buying process. I consider factors such as price per square foot,
                  localized appreciation rates, and potential resale value to find a home that not only satisfies your
                  needs and wants list, but also counts as a sound investment. With its proximity to world-class ski resorts,
                  thriving art, restaurant and brewery scene, and home to Weber State University, I truly believe Ogden is one
                  of the best places to live in the country. I love helping local Ogdenites find a new home, and also cherish
                  being an ambassador for folks moving to the region.
                </p>
              </div>
              <div className="col-lg-4">
                <Img fluid={this.props.data.images.edges[6].node.childImageSharp.fluid}/>
              </div>
            </div>
          </div>
        </section>

      {/*  <section className="page-section bg-secondary" id="featured">
          <div className="container">
            <h2 className="text-center mt-0">FEATURED LISTINGS BY NEIGHBORHOOD</h2>
            <hr className="divider my-4"/>
            <div className="row">
              <div className="col-lg-4 col-md-6 text-left">
                <div className="mt-5">
                <Img fluid={this.props.data.images.edges[2].node.childImageSharp.fluid} alt="historic-district-home"/>
                  <a href="https://www.utahrealestate.com/1655505" target="_blank" rel="noopener noreferrer">See featured listing details <i className="fas fa-chevron-circle-right"></i></a>
                  <h3 className="h4 mb-2">Historic District</h3>
                  <p className="text-muted mb-0">
                    A dynamic neighborhood that features some of Ogden's most characterful homes,
                    you can find a variety of enchanting styles in the historic district, such as two-story Victorians or adorable
                    1920s-style Bungalows. If you want a revitalized historic home within walking distance of downtown, and you do
                    not mind the hustle and bustle of urban life, then the Historic District might be your ideal neighborhood.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 text-left">
                <div className="mt-5">
                <a className="portfolio-box" href="img/portfolio/fullsize/2.jpg" onClick={this.handlePortfolioClick.bind(this, 0)}>
                <Img fluid={this.props.data.images.edges[5].node.childImageSharp.fluid}/>
                </a>
                <a href="https://www.utahrealestate.com/1655856" target="_blank" rel="noopener noreferrer">See featured listing details <i className="fas fa-chevron-circle-right"></i></a>
                  <h3 className="h4 mb-2">Washington Terrace</h3>
                  <p className="text-muted mb-0">
                    Washington Terrace offers cost-effective urban options that are nevertheless a 10 minute drive from downtown,
                    McKay-Dee Hospital, and Weber State University. The homes tend to be larger 1950's-style suburban ranches and ramblers,
                    with a few new builds sprinkled throughout. If you are looking for a larger, more cost-effective home that still feels
                    close to all the action, the Terrace is an excellent place to look.
                  </p>

                </div>
              </div>
              <div className="col-lg-4 col-md-6 text-left">
                <div className="mt-5">
                <Img fluid={this.props.data.images.edges[0].node.childImageSharp.fluid}/>
                <a href="https://www.utahrealestate.com/1608218" target="_blank" rel="noopener noreferrer">See featured listing details <i className="fas fa-chevron-circle-right"></i></a>
                  <h3 className="h4 mb-2">East Bench</h3>
                  <p className="text-muted mb-0">
                    Nestled up against the Wasatch Mountains, Ogden's East Bench features quiet streets, mountain access, and proximity to
                    Ogden's exquisite recreational parks and Mt Ogden Golf Course. Expect fierce competition and potential bidding wars for
                    "East Bench Gems."
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 text-left">
                <div className="mt-5">
                <Img fluid={this.props.data.images.edges[3].node.childImageSharp.fluid}/>
                <a href="https://www.utahrealestate.com/1651015" target="_blank" rel="noopener noreferrer">See featured listing details <i className="fas fa-chevron-circle-right"></i></a>
                  <h3 className="h4 mb-2">North Ogden</h3>
                  <p className="text-muted mb-0">
                    If you want a beautiful new home with all of the modern amenities for a great price, then North Ogden is your neighborhood.
                    Houses range anywhere from $250,000 to $1,000,000, and are typically more opulent as you move closer to the mountain.
                    However, your dream home may come at the cost of a slightly longer commute.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 text-left">
                <div className="mt-5">
                <Img fluid={this.props.data.images.edges[4].node.childImageSharp.fluid}/>
                <a href="https://www.utahrealestate.com/1640949" target="_blank" rel="noopener noreferrer">See featured listing details <i className="fas fa-chevron-circle-right"></i></a>
                  <h3 className="h4 mb-2">Uintah/Shadow Valley</h3>
                  <p className="text-muted mb-0">
                    Just south of Ogden, and only 30 minutes north of Salt Lake City, the Shadow Valley and Uintah area features some of the
                    most premier homes in the area. The lots tend to be larger and heavily wooded, providing unparalleled privacy.
                    Many of the homes were built between the 1970's-2000's and might need some updating, but the money is well spent in this
                    prestigious and high-end area.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 text-left">
                <div className="mt-5">
                  <Img fluid={this.props.data.images.edges[1].node.childImageSharp.fluid}/>
                  <a href="https://www.utahrealestate.com/1644555" target="_blank" rel="noopener noreferrer">See featured listing details <i className="fas fa-chevron-circle-right"></i></a>
                  <h3 className="h4 mb-2">Eden</h3>
                  <p className="text-muted mb-0">
                    A beautiful valley, adjacent to lake-sized Pineview reservoir, and encircled by snow-capped mountains, Eden Valley deserves its name.
                    A ten minute drive from world-class ski resorts Powder Mountain and Snowbasin, Eden Valley features a breathtaking array of real estate options,
                    from idyllic cabin-like Alpine villas, to premium townhomes, and mansions encamped on sprawling farmland. Eden Valley is an ideal spot for those
                    looking for a gem of a second home, while also boasting a charming year round community. An hour from Salt Lake City, and 20 minutes from Ogden,
                    this valley truly counts as a second Eden on earth.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section className="page-section bg-secondary" id="connect">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 text-center">
                <h2 className="mt-0">CONNECT</h2>
                <hr className="divider my-4"/>
                <p className="text-muted text-left mb-5">Feel free to join my Facebook group "East Bench Guru," replete with monthly market reports, intriguing new listings, and lively discussion regarding Ogden's dynamic real estate market. Or contact me by phone or email to begin looking for your new Ogden area home today.</p>
                <div className="row">
                  <div className="col-lg ml-auto text-center mb-5 mb-lg-0">
                    <a href="https://www.facebook.com/groups/274423653503102" target="_blank" rel="noopener noreferrer">
                      <i className="fab fa-facebook fa-4x mb-3 text-muted"></i>
                      <div>Join Group</div>
                    </a>
                  </div>
                  <div className="col-lg mr-auto text-center mb-5">
                    <a href="mailto:will.underkuffler@gmail.com?subject=Website Inquiry">
                      <i className="fas fa-envelope fa-4x mb-3 text-muted"></i>
                      <div>will.underkuffler@gmail.com</div>
                    </a>
                  </div>
                  <div className="col-lg mr-auto text-center">
                    <a href="tel:2074415960">
                      <i className="fas fa-phone fa-4x mb-3 text-muted"></i>
                      <div>(207)441-5960</div>
                    </a>
                  </div>
                </div>
                <p className="text-muted text-left mb-5"></p>
              </div>
            </div>
          </div>
        </section>
        <PortfolioModal show={this.state.modalShow} onHide={() => this.setModal(false, 0)}>
          <PortfolioCarousel images={this.props.data.images.edges} current={this.state.modalCurrent}/>
        </PortfolioModal>
      </Layout>
    )
  }
}


export const imageData = graphql`
  query {
    images: allFile(filter: {relativePath: {glob: "portfolio/fullsize/*.jpg"}}, sort: {fields: name}) {
      edges {
        node {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
